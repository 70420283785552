import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { LoadingLayer } from '../../components/LoadingLayer';

export const LoadingContext = React.createContext(false);

export function LoadingProvider({ children }) {
  const { initialized } = useKeycloak();
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={[loading || !initialized, setLoading]}>
      {loading || (!initialized && <LoadingLayer />)}
      {children}
    </LoadingContext.Provider>
  );
}
