import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};

  &:after {
    content: ' ';
    display: block;
    width: ${props => props.size * 0.8 + 'px'};
    height: ${props => props.size * 0.8 + 'px'};
    margin: 8px;
    border-radius: 50%;
    border: ${props => props.size / 10 + 'px'} solid #90caff;
    border-color: #90caff transparent #90caff transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

export function Loader(props) {
  const { size, className } = props;

  return <Spinner className={className} size={size || 80} />;
}
