import Keycloak from 'keycloak-js';

//TODO use enviroment variables
const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
});

export const keycloakProviderInitConfig = {
  onLoad: 'login-required'
};

export default keycloak;
