import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import SideMenu from './pages/SideMenu';
import LogoImg from './assets/icon-omnilogic-white.png';
import LogoImgFull from './assets/logo-omnilogic-white.png';
import Routes from './routes';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Layout } from 'antd';
import { MainTheme } from './styles/theme';
import ExpandedMenuContext from './utils/contexts/ExpandedMenuContext';
import { KeycloakProvider } from '@react-keycloak/web';
import KeyCloak, {
  keycloakProviderInitConfig
} from './configs/keycloak-config';
import { LoadingProvider } from './utils/contexts/LoadingContext';
import { SelectedStoreProvider } from './utils/contexts/SelectedStoreContext';
import { TemplateContextProvider } from './pages/intelligentStore/personalShop/dynamic-Showcase/context/TemplateContext';

const { Content, Sider } = Layout;

const Logo = styled.img``;

class App extends Component {
  state = {
    collapsed: false
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <KeycloakProvider
        keycloak={KeyCloak}
        initConfig={keycloakProviderInitConfig}
      >
        <LoadingProvider>
          <SelectedStoreProvider>
            <DndProvider backend={Backend}>
              <ThemeProvider theme={MainTheme}>
                <BrowserRouter>
                  <Layout style={{ minHeight: '100vh' }}>
                    <Sider
                      collapsible
                      width={280}
                      collapsed={this.state.collapsed}
                      onCollapse={this.onCollapse}
                      style={{
                        backgroundColor: MainTheme.mainColor,
                        display: 'flex',
                        justifyContent: 'center',
                        overflow: 'auto',
                        height: '100vh',
                        left: 0
                      }}
                    >
                      <Logo
                        className='logo'
                        src={this.state.collapsed ? LogoImg : LogoImgFull}
                        alt='logo'
                        style={{
                          width: '100%',
                          padding: this.state.collapsed
                            ? '10px 16px'
                            : '10px 32px',
                          marginBottom: '60px'
                        }}
                      />
                      <SideMenu />
                    </Sider>
                    <ExpandedMenuContext.Provider value={!this.state.collapsed}>
                      <Layout style={{ background: '#fff', padding: 0 }}>
                        <Content
                          style={{
                            padding: '0 16px',
                            overflow: 'auto',
                            height: '100vh'
                          }}
                        >
                        <TemplateContextProvider>
                          <Routes />
                        </TemplateContextProvider>
                        </Content>
                      </Layout>
                    </ExpandedMenuContext.Provider>
                  </Layout>
                </BrowserRouter>
              </ThemeProvider>
            </DndProvider>
          </SelectedStoreProvider>
        </LoadingProvider>
      </KeycloakProvider>
    );
  }
}

export default App;
