import RolesEnum from '../../enums/RolesEnum';
import StoresConfig from '../../../configs/store-config';
import { isNullableType } from 'graphql';

export default {
  getRoles: keycloak =>
    (keycloak &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.realm_access &&
      keycloak.tokenParsed.realm_access.roles) ||
    [],

  getStores: keycloak => {
    var storeStr = "";
    var store_custom = "";
    var newStores = "";
    const roles =
      (keycloak &&
        keycloak.tokenParsed &&
        keycloak.tokenParsed.realm_access &&
        keycloak.tokenParsed.realm_access.roles) ||
      [];
//Validação e separação de várias stores (Attributes do keycloak) para um array
    store_custom = keycloak && keycloak.tokenParsed && keycloak.tokenParsed.stores;
    if (store_custom !== undefined || isNullableType(store_custom)){
         storeStr = store_custom.toString();
         newStores = storeStr.trim().split(",");
    }

    return roles.includes(RolesEnum.ADMIN)
      ? Object.keys(StoresConfig)
      : newStores  || [];
  },

  roleCheck: (roles, rolesAllowed) => {
    if (!rolesAllowed) return true;
    for (let userRole of roles) {
      if (rolesAllowed.includes(userRole)) return true;
    }
    return false;
  },
  getMetabaseKey: keycloak =>
    keycloak && keycloak.tokenParsed && keycloak.tokenParsed.metabase,
  
  getUsername: keycloak =>
    keycloak && keycloak.tokenParsed && keycloak.tokenParsed.preferred_username
};
