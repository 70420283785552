import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 0;
  .btnBlue {
    background-color: #243891;
    border-radius: 25px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 30px;
    width: 100px;
    font-size: 11px;
    letter-spacing: 0.5px;
    margin-left: 20px;
  }
  button:disabled {
    background-color: #d0d0d0;
    cursor: not-allowed;
  }
`;

export function BlueButton(props) {
  return (
    <Container className={props.className}>
      <button
        className="btnBlue"
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </Container>
  );
}
