import React from 'react';
import styled from 'styled-components';
import { Loader } from './Loader';

const WrapperLayer = styled.div`
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'}
  top: ${props => props.top || 0};
  left: ${props => props.right || 0};
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  background: #90caff47;
  display:flex;
  z-index: 5;
`;

const CenteredLoader = styled(Loader)`
  margin: auto;
`;

export function LoadingLayer(props) {
  return (
    <WrapperLayer {...props}>
      <CenteredLoader />
    </WrapperLayer>
  );
}
