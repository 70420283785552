export const MainTheme = {
  mainColor: '#243891',
  mainColorDark25: '#1d2d74',
  mainColorDark50: '#17245d',
  accentColor: '#53ABD1',
  accentColorDark: '#004794',
  accentColorTransp: '#265164',
  warnColor: '#ef5350',
  disabledColor: '#43425d88',
  disabledColorLight: '#43425d55'
};
