import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import RolesEnum from '../utils/enums/RolesEnum';
//REVIEW: React.lazy não funciona com server side rendering -> poderia usar LoadableComponent
//        Misturando ingles e portugês, achei estranho
//        Não entendi onde essas rotas estão sendo utilizadas

// SEMANTIC SEARCH
const SemanticHome = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/home')
);
const SemanticPedidos = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/orders')
);
const SemanticRelatorios = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/reports')
);
const SemanticOfertas = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/offers')
);
const SemanticResultados = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/results')
);
const SemanticUsuarios = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/users')
);
const SemanticSinonimos = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/synonym')
);
const SemanticRedirecionamento = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/redirection')
);
const SemanticBanner = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/banner')
);
const SemanticBoosting = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/boosting')
);
const SemanticTraducoes = React.lazy(() =>
  import('../pages/intelligentStore/semanticSearch/translates')
);

// SMART PAGES
const SmartHome = React.lazy(() =>
  import('../pages/intelligentStore/smartPages/home')
);
const SmartPedidos = React.lazy(() =>
  import('../pages/intelligentStore/smartPages/orders')
);
const SmartResultados = React.lazy(() =>
  import('../pages/intelligentStore/smartPages/results')
);
const SmartLanding = React.lazy(() =>
  import('../pages/intelligentStore/smartPages/landing')
);

// PERSONAL SHOP
const PersonalHome = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/home')
);
const PersonalPedidos = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/orders')
);
const PersonalOfertas = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/offers')
);

const PersonalStaticSetup = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/setup')
);

const PersonalStaticShowcase = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/static-Showcase')
);

const PersonalDynamicSetup = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/dynamic-Showcase/setup')
);

const PersonalDynamicShowcase = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/dynamic-Showcase/index')
);

const PersonalDynamicShowcaseDivCreator = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/dynamic-Showcase/dynamic-showcase-components/DivCreator/index')
);

const PersonalRelatorios = React.lazy(() =>
  import('../pages/intelligentStore/personalShop/reports')
);

// BEHAVIOR TRIGGERS

const BehaviorTriggersResultados = React.lazy(() =>
  import('../pages/intelligentStore/behaviorTriggers/resultados')
);

const BehaviorTriggersOptOuts = React.lazy(() =>
  import('../pages/intelligentStore/behaviorTriggers/optOuts')
);

const BehaviorTriggersTemplates = React.lazy(() =>
  import('../pages/intelligentStore/behaviorTriggers/templates')
);

const BehaviorTriggersRulers = React.lazy(() =>
  import('../pages/intelligentStore/behaviorTriggers/rulers')
);

const BehaviorTriggersHeatMap = React.lazy(() => 
  import('../pages/intelligentStore/behaviorTriggers/heatmap')
);

// PRODUCT CLOUD

const AdvancedFiltersDashboard = React.lazy(() =>
  import('../pages/productCloud/dashboard')
);
const AdvancedFiltersReports = React.lazy(() => 
  import('../pages/productCloud/reports')
);
const AdvancedFiltersReportsDetails = React.lazy(() => 
  import('../pages/productCloud/detailsReport')
);

// SELLER PANEL

const SellerHome = React.lazy(() => import('../pages/sellerPanel/home'));
const SellerUsers = React.lazy(() =>
  import('../pages/sellerPanel/usersFunnel')
);
const SellerInconsistencias = React.lazy(() =>
  import('../pages/sellerPanel/inconsistances')
);
const SellerOfertas = React.lazy(() => import('../pages/sellerPanel/offers'));
const SellerPublic = React.lazy(() =>
  import('../pages/sellerPanel/publications')
);

// ADM

const Administration = React.lazy(() => import('../pages/administrativo'));

/*******************************************/
/*  Menus must have title and link.        */
/*  Menus can have rolesAllowed and img.   */
/*******************************************/

export const routeProps = [
  {
    title: 'Intelligent Store',
    img: 'intelligent-store-white',
    menus: [
      {
        title: 'Semantic Search',
        link: 'semanticsearch',
        menus: [
          {
            component: SemanticHome,
            title: 'home',
            link: 'home',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticPedidos,
            title: 'pedidos',
            link: 'pedidos',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticRelatorios,
            title: 'Relatórios',
            link: 'relatorios',
            rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]
          },
          {
            component: SemanticOfertas,
            title: 'ofertas',
            link: 'ofertas',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticResultados,
            title: 'resultados',
            link: 'resultados',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticUsuarios,
            title: 'usuários',
            link: 'usuarios',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticSinonimos,
            title: 'sinônimos',
            link: 'sinonimos',
            rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]
          },
          {
            component: SemanticRedirecionamento,
            title: 'redirecionamento',
            link: 'redirecionamento',
            rolesAllowed: RolesEnum.REDIRECTION_ANY
          },
          {
            component: SemanticBanner,
            title: 'banner',
            link: 'banner',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: SemanticBoosting,
            title: 'Boosting',
            link: 'boosting',
            rolesAllowed: RolesEnum.BOOSTING_ANY
          },
          {
            component: SemanticTraducoes,
            title: 'traduções',
            link: 'traducoes',
            rolesAllowed: [RolesEnum.ADMIN]
          }
        ]
      },
      {
        rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN],
        title: 'Personal Shop',
        link: 'personalshop',
        menus: [
          {
            component: PersonalHome,
            title: 'home',
            link: 'home',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: PersonalPedidos,
            title: 'pedidos',
            link: 'pedidos',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: PersonalOfertas,
            title: 'Ofertas',
            link: 'ofertas',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: PersonalRelatorios,
            title: 'Relatórios',
            link: 'relatorios'
          },
          {
            rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN],
            title: 'Vitrine',
            menus: [
              {
                component: PersonalStaticShowcase,
                title: 'Estática',
                link: 'vitrine-estatica'
              },
              {
                rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN],
                title: "Dinâmica",
                menus: [
                  {
                    component: PersonalDynamicShowcase,
                    title: 'Editor',
                    link: 'vitrine-dinamica',
                  },
                  {
                    component: PersonalDynamicShowcaseDivCreator,
                    title: 'Criador de Divs',
                    link: 'criador-de-divs',
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        rolesAllowed: [RolesEnum.ADMIN],
        title: 'Smart Pages',
        link: 'smartpages',
        menus: [
          { component: SmartHome, title: 'home', link: 'home' },
          { component: SmartPedidos, title: 'pedidos', link: 'pedidos' },
          {
            component: SmartResultados,
            title: 'resultados',
            link: 'resultados'
          },
          { component: SmartLanding, title: 'landing', link: 'landing' }
        ]
      },
      {
        rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN],
        title: 'Behavior Triggers',
        link: 'behaviortriggers',
        menus: [
          { title: 'Home', link: 'home', rolesAllowed: [RolesEnum.ADMIN] },
          {
            component: BehaviorTriggersResultados,
            title: 'Resultados',
            link: 'resultados'
          },
          {
            title: 'Ofertas',
            link: 'ofertas',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            title: 'Pedidos',
            link: 'pedidos',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          {
            component: BehaviorTriggersRulers,
            title: 'Réguas',
            link: 'rulers',
            rolesAllowed: [RolesEnum.ADMIN]
          },
          { title: 'Setup', link: 'setup', rolesAllowed: [RolesEnum.ADMIN] },
          {
            component: BehaviorTriggersTemplates,
            title: 'Templates',
            link: 'templates',
            rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]
          },
          {
            component: BehaviorTriggersOptOuts,
            title: 'Opt Outs',
            link: 'opt-outs'
          },
          {
            component: BehaviorTriggersHeatMap,
            title: 'Mapa de calor',
            link: 'heatmap',
            rolesAllowed: [RolesEnum.ADMIN]
          }
        ]
      }
    ]
  },
  {
    rolesAllowed: [RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN],
    title: 'Product Cloud',
    img: 'productCloud',
    link: 'productcloud',
    menus: [
      { title: 'Dashboard ', link: 'dashboard' },
      { title: 'Relatórios', link: 'reports'}
    ]
  },
  {
    rolesAllowed: [RolesEnum.ADMIN],
    title: 'Customer Cloud',
    img: 'customer-cloud',
    menus: [
      { title: 'Home', link: '/' },
      { title: 'Fichas', link: '/' },
      { title: 'Setup de Fichas', link: '/' },
      { title: 'Inconsistências', link: '/' },
      { title: 'Ofertas', link: '/' },
      { title: 'Relatórios ', link: '/' }
    ]
  },
  {
    rolesAllowed: [RolesEnum.ADMIN],
    title: 'Seller Panel',
    img: 'seller-panel',
    menus: [
      { component: SellerHome, title: 'Home', link: 'home' },
      { component: SellerUsers, title: 'Usuários', link: 'usuarios' },
      {
        component: SellerInconsistencias,
        title: 'Inconsitencias',
        link: 'inconsitencias'
      },
      { component: SellerOfertas, title: 'Ofertas', link: 'ofertas' },
      {
        component: SellerPublic,
        title: 'Publicações ',
        link: 'publicacoes'
      }
    ]
  },
  {
    rolesAllowed: [RolesEnum.ADMIN],
    title: 'Administrativo',
    img: 'gear',
    menus: [
      {
        component: Administration,
        title: 'Administrativo',
        link: 'administrativo'
      }
    ]
  }
];

//TODO build routes bassed on routeprops, create roles enum
const Routes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {/* INTELLIGENT STORE */}
      <PrivateRoute
        path='/semanticsearch/home'
        component={SemanticHome}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/pedidos'
        component={SemanticPedidos}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/relatorios'
        component={SemanticRelatorios}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/ofertas'
        component={SemanticOfertas}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/resultados'
        component={SemanticResultados}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/usuarios'
        component={SemanticUsuarios}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/sinonimos'
        component={SemanticSinonimos}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/redirecionamento'
        component={SemanticRedirecionamento}
        rolesAllowed={RolesEnum.REDIRECTION_ANY}
      />
      <PrivateRoute 
        path='/semanticsearch/banner' 
        component={SemanticBanner} 
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/semanticsearch/boosting'
        component={SemanticBoosting}
        rolesAllowed={RolesEnum.BOOSTING_ANY}
      />
      <PrivateRoute
        path='/semanticsearch/traducoes'
        component={SemanticTraducoes}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/smartpages/home'
        component={SmartHome}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/smartpages/pedidos'
        component={SmartPedidos}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/smartpages/resultados'
        component={SmartResultados}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/smartpages/landing'
        component={SmartLanding}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/home'
        component={PersonalHome}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/pedidos'
        component={PersonalPedidos}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/ofertas'
        component={PersonalOfertas}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/setup'
        component={PersonalStaticSetup}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/relatorios'
        component={PersonalRelatorios}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/vitrine-estatica'
        component={PersonalStaticShowcase}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/personalshop/vitrine-dinamica/setup'
        component={PersonalDynamicSetup}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
        <PrivateRoute
        path='/personalshop/vitrine-dinamica'
        component={PersonalDynamicShowcase}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
        <PrivateRoute
        path='/personalshop/criador-de-divs'
        component={PersonalDynamicShowcaseDivCreator}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      SELLER PANEL
      <PrivateRoute
        path='/sellerpanel/home'
        component={SellerHome}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/sellerpanel/inconsistencias'
        component={SellerInconsistencias}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/sellerpanel/ofertas'
        component={SellerOfertas}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/sellerpanel/publicacoes'
        component={SellerPublic}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/sellerpanel/publicacoes/:id'
        component={SellerPublic}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/sellerpanel/usuarios'
        component={SellerUsers}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/behaviortriggers/resultados'
        component={BehaviorTriggersResultados}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path='/behaviortriggers/opt-outs'
        component={BehaviorTriggersOptOuts}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path={['/behaviortriggers/rulers', '/behaviortriggers/rulers?id=:id']}
        component={BehaviorTriggersRulers}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path={[
          '/behaviortriggers/templates',
          '/behaviortriggers/templates?id=:id'
        ]}
        component={BehaviorTriggersTemplates}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path={[
          '/behaviortriggers/heatmap',
        ]}
        component={BehaviorTriggersHeatMap}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <PrivateRoute
        path='/productcloud/dashboard'
        component={AdvancedFiltersDashboard}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path='/productcloud/reports'
        component={AdvancedFiltersReports}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path='/productcloud/report/:name'
        component={AdvancedFiltersReportsDetails}
        rolesAllowed={[RolesEnum.ADMIN, RolesEnum.PARTNER_ADMIN]}
      />
      <PrivateRoute
        path='/administrativo'
        component={Administration}
        rolesAllowed={[RolesEnum.ADMIN]}
      />
      <Redirect from='/' exact to='/semanticsearch/boosting' />
    </Switch>
  </Suspense>
);

export default Routes;
