import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import KeyCloakHelper from '../utils/helpers/KeyCloakHelper';

function PrivateRoute({
  component: Component,
  history,
  rolesAllowed,
  ...props
}) {
  const { keycloak, initialized } = useKeycloak();

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const isAuthorized =
      keycloak &&
      keycloak.token &&
      KeyCloakHelper.roleCheck(KeyCloakHelper.getRoles(keycloak), rolesAllowed);
    setAuthorized(isAuthorized);
    // if (initialized && !isAuthorized) history.push('/'); //TODO verificar isso
  }, [keycloak.token, initialized]);

  const renderCtl = routeProps => <Component {...routeProps}> </Component>;

  return authorized ? <Route render={renderCtl} {...props} /> : null;
}

export default withRouter(PrivateRoute);
