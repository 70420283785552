import React, { useState, createContext, useContext } from 'react';

const TemplateContext = createContext({});

export const useTemplateContext = () => {
  return useContext(TemplateContext);
};

export const TemplateContextProvider = ({ children }) => {
  const [templateName, setTemplateName] = useState(null);
  const [stage, setStage] = useState(null);
  const [minProducts, setMinProducts] = useState(null);
  const [maxProducts, setMaxProducts] = useState(null);
  const [structure, setStructure] = useState(null);
  const [onMountedScript, setOnMountedScript] = useState(null);
  const [firstLoadScript, setFirstLoadScript] = useState(null);
  const [externalDependencyLinks, setExternalDependencyLinks] = useState([]);
  const [style, setStyle] = useState(null);

  const handleServerData = (object) => {
        setTemplateName(object.data.templateName);
        setStage(object.data.stage);
        setMinProducts(object.data.minProducts);
        setMaxProducts(object.data.maxProducts);
        setStructure(object.data.structure);
        setOnMountedScript(object.data.onMountedScript);
        setFirstLoadScript(object.data.firstLoadScript);
        setExternalDependencyLinks(object.data.externalDependencyLinks);
        setStyle(object.data.style);
  }

  const getStateObject = () => {
    return {
      templateName: templateName,
      stage: stage,
      minProducts: minProducts,
      maxProducts: maxProducts,
      structure: structure,
      onMountedScript: onMountedScript,
      firstLoadScript: firstLoadScript,
      externalDependencyLinks: externalDependencyLinks,
      style: style
    }
  }

  const clearTemplateData = () => {
    setTemplateName(null);
    setStage(null);
    setMinProducts(null);
    setMaxProducts(null);
    setStructure(null);
    setOnMountedScript(null);
    setFirstLoadScript(null);
    setExternalDependencyLinks([]);
    setStyle(null);
  }

  return (
    <TemplateContext.Provider value=
    {{
      handleServerData,
      getStateObject,
      clearTemplateData,
      update: {
        setTemplateName,
        setStage,
        setMinProducts,
        setMaxProducts,
        setStructure,
        setOnMountedScript,
        setFirstLoadScript,
        setExternalDependencyLinks,
        setStyle
      },
      state: {
        templateName,
        stage,
        minProducts,
        maxProducts,
        structure,
        onMountedScript,
        firstLoadScript,
        externalDependencyLinks,
        style,
      }
    }}>
      {children}
    </TemplateContext.Provider>
  )
};

