import React, { useState } from 'react';
import styled from 'styled-components';
import { BlueButton } from './BlueButton';
import { Select } from 'antd';

const { Option } = Select;

const Container = styled.div`
  .modalWrapper {
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    transition: all 0.6s;
    border-radius: 7px;
    width: 30%;
    margin-top: 35vh;
    margin-left: 35vw;
    left: 0;
    top: 0;
    padding: 32px 38px;
    .modalHeader {
      h6 {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #43425d;
        opacity: 0.5;
      }
      h4 {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #43425d;
        opacity: 0.5;
      }
    }
    .modalBody {
      .list {
        ul {
          margin-bottom: 0px;
          list-style: none;
          li {
            padding: 8px 0;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.1em;
            color: ${props => props.theme.mainColor};
            border-bottom: 0.5px solid #53abd1;
          }
        }
      }
      .modalButtons {
        margin-top: 27px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .backDrop {
    left: 0;
    top: 0;
    z-index: 9998;
    background-color: rgba(14, 18, 55, 0.8);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
  }
`;

const SelectInput = styled(Select)`
  margin: 5% 0;
`;

export default function SelectModal({ show, close, submit, options, text }) {
  const [selected, setSelected] = useState(options && options[0]);

  const handleSubmit = evt => {
    evt.preventDefault();
    submit(selected);
  };

  if (show)
    document.querySelector('body').classList.add('overflow-body-disabled');

  return (
    <Container>
      {show ? (
        <div className='backDrop' onClick={() => closeOnBackgroundClick()} />
      ) : null}
      <div
        className='modalWrapper'
        style={{
          transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
          opacity: show ? '1' : '0',
          background: '#fff'
        }}
      >
        <div className='modalHeader'>
          <h4>{text}</h4>
        </div>
        <form className='modalBody' onSubmit={handleSubmit}>
          <SelectInput
            value={selected}
            onChange={setSelected}
            dropdownStyle={{ zIndex: 10000 }}
          >
            {options &&
              options.map(o => (
                <Option key={o} value={o}>
                  {o}
                </Option>
              ))}
          </SelectInput>
          <BlueButton type='submit'>Confirmar</BlueButton>
        </form>
      </div>
    </Container>
  );

  function closeOnBackgroundClick() {
    document.querySelector('body').classList.remove('overflow-body-disabled');
    close(false);
  }
}
