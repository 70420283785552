import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { createClient, Provider } from 'urql';

const client = createClient({
  url:
    process.env.REACT_APP_ENV === 'sandbox'
      ? 'https://graphql-dev.omnilogic.com.br/graphql'
      : 'https://graphql.omnilogic.com.br/graphql'
});

render(
  <Provider value={client}>
    <App />
  </Provider>,
  document.getElementById('root')
);
