import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Menu } from 'antd';
import { routeProps } from '../../routes';
import { Link } from 'react-router-dom';
import KeyCloakHelper from '../../utils/helpers/KeyCloakHelper';
import { useKeycloak } from '@react-keycloak/web';
import SelectModal from '../../components/SelectModal';
import { SelectedStoreContext } from '../../utils/contexts/SelectedStoreContext';

const SubMenu = Menu.SubMenu;

const Image = styled.img`
  height: 14px;
  margin-right: 10px;
`;

// Theme is reserved for both ant component and styled component.
// Created a wrapper for the menu as an easy solution

const DarkMenu = props => (
  <Menu className={props.className} theme='dark' {...props}>
    {props.children}
  </Menu>
);

const OmniMenu = styled(DarkMenu)`
  background-color: ${props => props.theme.mainColor};
  .ant-menu-inline.ant-menu-sub {
    background-color: ${props => props.theme.mainColorDark25};
    .ant-menu-inline.ant-menu-sub {
      background-color: ${props => props.theme.mainColorDark50};
    }
  }
`;

const generatePath = (left, right) => {
  const hasSeparator =
    (!left || left.slice(-1) !== '/') && right && right[0] !== '/';
  return `${left || ''}${hasSeparator ? '/' : ''}${right || ''}`;
};

const renderMenu = (menu, path, userRoles) => {
  if (KeyCloakHelper.roleCheck(userRoles, menu.rolesAllowed))
    if (menu.menus) {
      return (
        <SubMenu
          key={menu.title}
          text={menu.title}
          title={
            <div>
              {menu.img && (
                <Image
                  src={require(`../../assets/icons/${menu.img}.svg`)}
                  className='anticon'
                  alt='seta'
                />
              )}
              <span>{menu.title.toUpperCase()}</span>
            </div>
          }
        >
          {menu.menus.map(m =>
            renderMenu(m, generatePath(path, menu.link), userRoles)
          )}
          `
        </SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menu.title}>
          {menu.title.toUpperCase()}
          <Link to={generatePath(path, menu.link)} />
        </Menu.Item>
      );
    }
};

function SideMenu() {
  const { keycloak } = useKeycloak();

  const [selectionOpen, setSelectionOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useContext(SelectedStoreContext);

  const openStoreSelection = () => {
    setSelectionOpen(true);
  };

  const handleStoreSubmit = store => {
    setSelectedStore(store);
    setSelectionOpen(false);
  };

  return (
    <>
      <SelectModal
        show={selectionOpen}
        submit={handleStoreSubmit}
        close={() => {
          setSelectionOpen(false);
        }}
        options={KeyCloakHelper.getStores(keycloak).sort()}
        text='Selecione a Loja desejada'
      />
      <OmniMenu mode='inline'>
        {KeyCloakHelper.getStores(keycloak).length >= 1 && (
          <Menu.Item key={'select'} onClick={openStoreSelection}>
            <div>
              <Image
                src={require('../../assets/icons/layers-white.svg')}
                className='anticon'
                alt='select'
              />
              <span>{selectedStore && selectedStore.toUpperCase()}</span>
            </div>
          </Menu.Item>
        )}
        {routeProps.map(m =>
          renderMenu(m, '', KeyCloakHelper.getRoles(keycloak))
        )}
        <Menu.Item key={'logout'} onClick={keycloak.logout}>
          <div>
            <Image
              src={require('../../assets/icons/angle-left.svg')}
              className='anticon'
              alt='logout'
            />
            <span>SAIR</span>
          </div>
        </Menu.Item>
      </OmniMenu>
    </>
  );
}

export default SideMenu;
