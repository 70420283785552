export default {
  ADMIN: 'admin',
  PARTNER_ADMIN: 'partner_admin',
  REDIRECTION_CREATE: 'redirection.create',
  REDIRECTION_READ: 'redirection.read',
  REDIRECTION_UPDATE: 'redirection.update',
  REDIRECTION_DELETE: 'redirection.delete',
  REDIRECTION_ANY: [
    'redirection.create',
    'redirection.read',
    'redirection.update',
    'redirection.delete'
  ],
  BOOSTING_CREATE: 'boosting.create',
  BOOSTING_READ: 'boosting.read',
  BOOSTING_UPDATE: 'boosting.update',
  BOOSTING_DELETE: 'boosting.delete',
  BOOSTING_ANY: [
    'boosting.create',
    'boosting.read',
    'boosting.update',
    'boosting.delete'
  ]
};
